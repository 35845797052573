// ######## Estilos Globales ########

html {
  scroll-behavior: smooth !important;
}

body {
  font-family: 'Roboto', sans-serif !important;
  font-weight: bold;
  background-color: #f8f9fa !important;
}

body::-webkit-scrollbar,
.table-body::-webkit-scrollbar,
.lista-body::-webkit-scrollbar,
.modal-Garantia::-webkit-scrollbar,
.modal-notificaciones::-webkit-scrollbar {
  width: 10px;
  /* Tamaño del scroll en vertical */
  height: 0;
  /* Tamaño del scroll en horizontal */
  overflow-x: hidden;
  background-color: #E8EAF6 !important;
}

body::-webkit-scrollbar-thumb,
.modal-Garantia::-webkit-scrollbar-thumb,
.modal-notificaciones::-webkit-scrollbar-thumb,
.lista-body::-webkit-scrollbar-thumb {
  background: #2c7be5 !important;
}

// .modal-body{
//   background-color: #e0e0e0 !important;
// }
.table-body::-webkit-scrollbar-thumb,
.lista-body::-webkit-scrollbar-thumb,
.modal-Garantia::-webkit-scrollbar-thumb,
.modal-notificaciones::-webkit-scrollbar-thumb {
  // background: #3949AB;
  background-color: #2c7be5;
  border-radius: 4px;
}

.requeridoIzquierda:before {
  content: " *";
  color: red;
  padding-right: 5px;
}
// Login
.login {
  overflow-y: hidden;
  /* Hide vertical scrollbar */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
}

.link-recupero {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}

.logo-login {
  width: 220px;
}

.logo-menu {
  width: 160px;
}

@media (min-width: 998px){
  .logo-menu {
    width: 180px;
  }
}


.noti {
  z-index: 5;
  position: relative;
  top: 2em;
  right: 2em;
}

#spinner-login {
  display: none;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
body::-webkit-scrollbar-thumb:hover {
  background: #283593;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
body::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

// ######## Navbar ########
.fuente {
  font-size: 16px !important;
}

[class^="bi-"]::before,
[class*=" bi-"]::before {
  font-size: 18px;
  color: #eee;
  padding-right: 15px;
}

.navbar-nav .nav-link {
  font-size: 20px;
}

a#navbarDropdown {
  font-size: 20px;
}

.padd-menu-link {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.card-notificacion {
  width: 20rem;
}

.foto-perfil {
  width: 40px;
  height: 40px;
}

.foto-perfil-notificacion {
  width: 50px;
}

.foto-mi-perfil {
  width: 240px;
  height: 240px;
}

.contened-notificacion {
  border-top: 0.5px solid #f8f9fa !important;
  border-bottom: 0.5px solid #f8f9fa !important;
  padding: 10px;
}

.card-title-menu {
  font-size: 16px;
}

.card-text-menu {
  font-size: 14px;
}

.color-notificacion {
  color: #232e3c
}

.color-footer-notificacion {
  color: #2c7be5
}

.boton-notificacion:focus {
  box-shadow: none !important;
}

.notification-indicator-primary {
  background-color: #2c7be5;
}

.notification-indicator-primary::before {
  background-color: #2c7be5;
}

.notification-indicator {
  position: relative;
  content: '';
  width: 20px;
  right: -30px;
  top: 1px;
  height: 2rem;
  width: 2rem;
  border-radius: 55%;
  border: 2px solid #edf2f9;
  z-index: 1;
  padding: 2px 4px;
  font-size: 12px;
  font-weight: bold;
}

.bell-indicator{
  position: relative;
  width: 20px;
  right: -5px;
  top: 0.8em;
}

@media (min-width: 998px){
  .notification-indicator {
    position: relative;
    content: '';
    width: 20px;
    right: 1px;
    top: 4px;
    height: 2rem;
    width: 2rem;
    border-radius: 55%;
    border: 2px solid #edf2f9;
    z-index: 1;
    padding: 2px 4px;
    font-size: 12px;
    font-weight: bold;
  }
  
  .bell-indicator{
    position: relative;
    width: 20px;
    right: 1px;
    top: -0.4em;
  }
}

// .notification-indicator::before {
//   position: absolute;
//   content: '';
//   right: 23px;
//   top: 1px;
//   height: 1rem;
//   width: 1rem;
//   border-radius: 50%;
//   border: 2px solid #edf2f9;
//   z-index: 0;
// }

.fondo-perfil {
  background-color: #f8f9fa !important;
}

//######## Inicio ########
.color-montos {
  color: #1b5e20;
  font-weight: bold;
}

.color-textos {
  color: #2c7be5;
  font-weight: bold;
}

.hr-width {
  width: 15%;
}

.titulo {
  background-color: #f8f9fa !important;
}

.half-black {
  background: rgba(0, 0, 0, 0.8) !important;
}

.icono-correcto {
  width: 25px;
}

.hr-principal {
  height: 3px !important;
  color: #2c7be5 !important;
  opacity: 1 !important;
  width: 80px !important;
}

.color-textp-lista {
  color: #344050 !important;
}

.texto-lista {
  font-size: 13.2px !important;
  line-height: 20px;
}

.texto-notificacion-descripcion {
  font-size: 12.2px !important;
}
.notificacion{
  top: 40px;
  left: -2.5em;
}
.ancho-responsive{
  width: 100% !important;
}
@media only screen and (min-width: 576px)  {
  .notificacion{
    top: 40px;
    left: -1.8em;
  }
}
@media only screen and (min-width: 768px)  {
  .notificacion{
    top: 40px;
    left: 10px;
  }
}
@media only screen and (min-width: 1200px)  {
  .ancho-responsive{
    width: 75% !important;
  }
}
.color-header {
  background-color: #E8EAF6 !important;
}

.color-secundario {
  color: #3949AB;
}

.color-azul-fuerte {
  color: #283593;
}

.pad {
  border-radius: 10px !important;
}

.borde-redondo {
  border-radius: 30px !important;
}

.borde-0 {
  border-radius: 0 !important;
}

.borde-none {
  border: 0 !important;
}

.padding-foto-perfil {
  padding: 3px;
  background-color: #2c7be5
}

//Tablas
.tabla {
  height: 50vh;
  scroll-behavior: smooth;
  display: none;
}

.color-head {
  color: #000 !important;
  background-color: #006ea1;
  border-radius: 5px;
}

.td-texto {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
}

.table-head {
  font-size: 14px;
  border: 0;
}

.table-body-size {
  font-size: 13px;
  max-height: 400px !important;
  width: 100% !important;
}

.table-body {
  height: 300px !important;
  width: 500px;
}

.borde-tabla-izquierda {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.borde-tabla-derecha {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tr-tabla {
  table-layout: fixed;
  padding-left: 20px !important;
}

.options {
  width: 8%;
}

.options-left {
  width: 5%;
}

.spinner-height {
  height: 200px;
}

.transicion {
  transition: all .5 ease-in-out;
}

th.p-2.fw-bolder.text-center.h6 {
  font-size: 14px !important;
}

td.p-2.pt-3.td-texto.text-center {
  font-size: 13px !important;
}

button.btn.btn-primary.float-right {
  float: right !important;
  margin-right: 20px;
}

.flechaTabla {
  font-size: 8px;
  font-weight: lighter;
  color: #adadad;
  margin: 0 2px;
}
.flechaTabla-activo{
  color: #1160b7 !important;
}

.texto-tabla {
  font-size: 14px;
  color: #364d5c !important;
}

.texto-lookups{
  color: #1160b7 !important;
}

//Loader
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #2c7be5;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//Documentos
.documento-alto {
  height: 600px !important;
}

.doc-cards {
  max-height: 500px;
}

.lista-header {
  font-size: 14px !important;
  font-weight: 700;
}

.lista-body {
  max-height: 340px;
  font-size: 14px !important;
  font-weight: 500;
  overflow-x: hidden !important;
}

.prefijo {
  padding: 8px 10px;
}

.c-azul {
  color: #2c7be5 !important;
}

.fuente-lista {
  font-size: 14px !important;
}

.alto-mapa {
  height: 400px;
}

//Buscador
.buscador {
  width: 100%;
  float: right;
  padding: 5px;
  margin: 4px;
  padding-left: 60px;
  border-radius: 28px;
  border: none !important;

}

.Icon-inside {
  position: relative;
}

.Icon-inside i,
icono-buscador {
  position: absolute;
  left: 20px;
  top: -20px;
  padding: 10px 10px;
  color: #30A3F1;
}

.buscador-radius {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.buscador-radius-derecha {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.upload-file:hover {
  cursor: pointer;
}

//Notificaciones
.titulo-notificacion {
  font-size: 14px !important;
  font-weight: 800 !important;
}

.subtitulo-notificacion {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.subtitulo-select {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.adeltante {
  z-index: 1;
}

.atras {
  z-index: 999;
}

.color-notificacion {
  color: green !important;
}

.switch-custom {
  height: 1.3em !important;
  width: 2.5em !important;
  margin-right: 15px;
}

.separador-notificacion {
  margin-top: 5px;
  height: 3px;
  width: 60px;
  background-color: #2c7be5;
}

//Perfil
.perfil-nombre {
  font-size: 16px;
}

.perfil-email {
  font-size: 16px;
}

.perfil-link {
  font-size: 14px !important;
}

.padding-lista-perfil {
  padding-left: 5px !important;
}

//Modal
.contenedor-modal {
  background-color: transparent !important;
  border: none !important;
}

.border-none {
  border: none !important;
}

//Precalificacion
.separador-titulo-precalificacion {
  margin-top: 5px;
  height: 40px;
  width: 5px;
  background-color: #2c7be5;
}

@media (max-width: 600px) {
  .separador-titulo-precalificacion {
    height: 70px;
  }
}

.separador-titulo {
  margin-top: 5px;
  height: 40px;
  width: 5px;
  background-color: #2c7be5;
}

.lbl-precalificacion {
  font-size: 14px;
}

.contenedor-spinner {
  padding-right: 80px;
}

.form-control,
select#telefono {
  // background-color: #f5f5f5!important;
  font-weight: 600 !important;
  border-radius: 5px !important;
}

.mapa-google {
  min-width: 350px !important;
  min-height: 350px !important;
  max-width: 700px !important;
  max-height: 380px !important;
  margin-top: 20px !important;
}

.portada {
  background: url('../src/img/Back.png') no-repeat fixed center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;
  text-align: center;
}

.requerido:after {
  content: " *";
  color: red;
}

p.fw-bolder {
  font-size: 14px !important;
}

.sinborde {
  border: 0;
}

//ProgressBar
.indexedStep {
  color: white;
  width: 40px;
  height: 40px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: rgba(27, 176, 74, 1);
}

.RSPBprogression {
  background-color: rgba(27, 176, 74, 1) !important;
}

.ok {
  // font-size: 120px;
  color: rgba(27, 176, 74, 1)
}

.borde_discontinuo {
  // border-style:dashed;
  // border-color: #0485a2;
  border: 4px dashed #2c7be5;
  padding: 20px;
}

.custom-input-file {
  background-color: #fff;
  color: #2c7be5;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin: 0 auto 0;
  min-height: 15px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  width: 400px;
  border-radius: 15px;
}

input#file-upload-button {
  display: none !important;
  visibility: hidden !important;
}

.icono-information {
  font-size: 1.4em !important;
}

.posicion-index-2 {
  z-index: 2;
}

.posicion-index-3 {
  z-index: 2;
}

.mensaje-finalizacion {
  line-height: 24px;
  letter-spacing: 0.8px;
}

.estado-notificaciones {
  width: 15px;
  height: 15px;
}

.notificacion-completada {
  background-color: #198754;
}

.notificacion-pendiente {
  background-color: #ffc107;
}

.texto-notificacion-pendiente {
  font-size: 14px;
}

.texto-titulo-notificacion {
  background-color: #f9fafd;
}

.texto-descripcion-notificacion {
  background-color: #edf2f9;
}
.capitalize{
  text-transform: capitalize !important;
}

.cardSerie:hover{
  box-shadow: 0px 1px 24px -7px rgba(0,0,0,0.30);
  -webkit-box-shadow: 0px 1px 24px -7px rgba(0,0,0,0.30);
  -moz-box-shadow: 0px 1px 24px -7px rgba(0,0,0,0.30);
}

.cardNav{
  box-shadow: 1px 11px 63px -32px rgba(0,0,0,0.18);
  -webkit-box-shadow: 1px 11px 63px -32px rgba(0,0,0,0.18);
  -moz-box-shadow: 1px 11px 63px -32px rgba(0,0,0,0.18);
}
.badge-personalizado-verde{
  font-size: 12.5px !important; 
  background-color: #28a745 !important;
  font-weight: lighter !important;
  font-weight: 700;
  
}
.badge-personalizado-amarillo{
  font-size: 12.5px !important; 
  background-color: #d9c80d !important;
  font-weight: lighter !important;
  font-weight: 700;
  
}
.badge-personalizado-naranja{
  font-size: 12.5px !important; 
  background-color: #fd7e14 !important;
  font-weight: lighter !important;
  font-weight: 700;
}

.badge-personalizado-rojo{
  font-size: 12.5px !important; 
  background-color: #dc3545 !important;
  font-weight: lighter !important;
  font-weight: 700;
  
}
.badge-personalizado-verde-validacion{
  font-size: 20px !important; 
  background-color: #28a745 !important;
  font-weight: lighter !important;
  font-weight: 700;
  color: #000;
}
.subrayado{
  margin-top: 4px;
  width: 95%;
  height: 5px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #eaedfd !important;
  // --bs-table-accent-bg: var(--bs-table-striped-bg);
}
.table-striped > tbody > tr{
  background-color: #ffffff !important;
}
.texto-grafico{
  font-size: 13px;
  font-weight: 600;
}
.buscador{
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  background-color: #e4e8f5 !important;
}

.modal-Garantia{
  height: 340px !important;
  overflow: scroll;
  font-size: 13.5px !important;
}
.modal-notificaciones{
  min-height: 70px !important;
  max-height: 180px !important;
  overflow: scroll;
  font-size: 13.5px !important;
}
.texto-modal-label{
  font-size: 13.5px !important;
}
.select#tipo {
  margin: 0 !important;
}
.alerta-pendientes{
  font-size: 12px;
}

.posicion{
  position:absolute; 
  z-index:2; 
  right:800px; 
}

.icono-circular{
  clip-path: circle();
  object-fit: cover
}
@import "~bootstrap/scss/bootstrap";